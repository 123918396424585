import { LinkIcon } from "@heroicons/react/solid";
export default function SectionLinks({ links, index }) {
  return (
    <div className="section-links mt-4">
      <h4 className="mb-4 text-xs font-bold">Section Links</h4>
      {links.map((link, i) => (
        <div key={`${index}-${i}`}>
          <a
            className="flex items-center mb-2 gap-2 text-sm"
            href={link.url}
            referrerPolicy=""
            target="_blank"
          >
            <LinkIcon className="w-4 h-4" />
            {link.name} {link.description && ` - ${link.description}`}
          </a>
        </div>
      ))}
    </div>
  );
}
