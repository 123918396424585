import React, { useEffect, useState } from "react";
import Section from "./components/Section";
import Loading from "./components/Loading";
import ReactPlayer from "react-player";
import ErrorMessage from "./components/Error";
import moment from "moment";
import "./App.css";

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const term = queryParams.get("station");
  const customLimit = queryParams.get("limit") ? queryParams.get("limit") : 12;
  const paginate =
    queryParams.get("paginate") && queryParams.get("paginate") === "true"
      ? true
      : false;
  const [sections, setSections] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [track, setTrack] = useState(
    "https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3"
  );
  const [streamProgress, setStreamProgress] = useState(0);
  const [streamPercent, setStreamPercent] = useState(0);
  const [duration, setDuration] = useState(0);
  const [buffering, setBuffering] = useState(false);
  const [length, setLength] = useState(0);
  const [player, setPlayer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [init, setInit] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1);

  const playAudio = (e) => {
    setPlaying(!playing);
  };

  const sendPostMessage = () => {
    let height;
    setTimeout(function () {
      height = document.getElementById("sectionscontainer").offsetHeight;
      console.log("sending", {
        frameHeight: height,
        eventName: "mrsections",
      });
      window.parent.postMessage(
        {
          frameHeight: height,
          eventName: "mrsections",
        },
        "*"
      );
    }, 10);
  };

  const newSeek = (percent) => {
    // console.log("seeking to", percent, percent / 100);
    // const newSpot = (1 / percent) * duration;
    // console.log(newSpot);
    player.seekTo(percent / 100, "fraction");
  };

  const updateProgress = (progress) => {
    // console.log("Progress", progress);
    var per = (progress.playedSeconds / length) * 100;
    // console.log("per", per);
    setStreamPercent(Math.floor(per));
    // setDuration(Math.floor(progress.loadedSeconds));
    setStreamProgress(Math.floor(Math.floor(progress.playedSeconds)));
  };

  const updateTime = (event, div) => {
    const thisDiv = document.getElementById(div);
    const width = window.getComputedStyle(thisDiv);
    const currentWidth = parseInt(width["width"]);
    var test = (event.nativeEvent.offsetX - thisDiv.offsetLeft) / currentWidth;
    player.seekTo(test);
  };
  // const updateTime = (event) => {
  //   var bcr = event.target.getBoundingClientRect();
  //   // if (this.state.track === null) {
  //   // return;
  //   // }
  //   // if (this.state.track !== null && this.state.track.isLive !== true) {
  //   let seekValue = event.clientX - event.target.offsetLeft;
  //   // let percent = seekValue / event.target.offsetWidth;
  //   let percent = (event.clientX - bcr.left) / bcr.width;
  //   console.log(percent);
  //   // this.state.player.seekTo(percent);
  //   // }
  //   player.seekTo(percent);
  // };

  const updateDuration = (newDuration) => {
    setLength(Math.floor(newDuration));
    setDuration(
      moment("2015-01-01")
        .startOf("day")
        .seconds(Math.floor(newDuration))
        .format("H:mm:ss")
    );
  };

  // const updateSeek = (time) => {
  //   var diff = streamProgress + time;
  //   player.seekTo(diff > 0 ? diff : 0, "seconds");
  // };

  let ref = (player) => {
    setPlayer(player);
  };

  useEffect(() => {
    console.log(term);
    const getSections = async () => {
      try {
        setLoading(true);
        var req = await fetch(
          `${process.env.REACT_APP_API}/public/station/${term}/sections?page=${page}&limit=${customLimit}`
        );
        const results = await req.json();
        console.log("Here", results);
        if (results.success === false) {
          setError(results.message);
        } else {
          if (results.data) {
            const temp = sections.concat(results.data);
            setSections(temp);
            setLoading(false);
            setPage(results.meta.current_page);
            setLimit(results.meta.last_page);
            console.log(
              "Updates sections",
              results.meta.current_page,
              results.meta.last_page
            );
            sendPostMessage();
          }
        }
      } catch (error) {
        setLoading(false);
        setError("Oops, something went wrong here");
        console.error("here", error);
      }
    };

    if (!term) {
      setError("There was a proble finding this station");
    } else {
      getSections();
    }
  }, [term, page]);

  const loadMore = async () => {
    const p = page + 1;
    setPage(p);
  };

  useEffect(() => {
    const toggle = async () => {
      if (init === false) {
        await setInit(true);
        await setPlaying(true);
        await setPlaying(false);
        await setPlaying(true);
      } else {
        await setPlaying(true);
      }
    };
    toggle();
  }, [track]);

  return (
    <div className="max-w-7xl mx-auto" id="sectionscontainer">
      {track && (
        <ReactPlayer
          ref={ref}
          progressInterval={50}
          onDuration={(progress) => updateDuration(progress)}
          // onPlay={(item) => console.log("here", item)}
          playing={playing}
          onProgress={(progress) => updateProgress(progress)}
          controls={false}
          onBuffer={() => setBuffering(true)}
          onBufferEnd={() => setBuffering(false)}
          onEnded={() => setPlaying(false)}
          height={0}
          width={0}
          url={track}
          config={{
            file: {
              forceAudio: true,
            },
          }}
        />
      )}
      {error && <ErrorMessage error={error} />}
      {loading && <Loading />}
      {sections.length > 0 && loading !== true && (
        <div className="grid grid-cols-12 gap-4 p-2">
          {sections.map((section, i) => (
            <Section
              updateTime={updateTime}
              newSeek={newSeek}
              buffering={buffering}
              section={section}
              setTrack={setTrack}
              playing={playing}
              track={track}
              index={i}
              key={i}
              playAudio={playAudio}
              streamProgress={streamProgress}
              streamPercent={streamPercent}
              duration={duration}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
            />
          ))}
        </div>
      )}
      {sections.length > 0 && loading === false && page < limit && (
        <div className="w-full w-100 text-center">
          <button
            className="mx-auto mt-8 w-full max-w-4xl p-3 bg-gray-100 mb-5"
            onClick={() => setPage(page + 1)}
          >
            Load More
          </button>
        </div>
      )}
      <div className="w-full w-100 text-center mb-8 px-4">
        <p className="text-center text-sm text-gray-600">
          Built by{" "}
          <a href="https://nicklarosa.net" target="_new">
            Nick La Rosa
          </a>{" "}
          for{" "}
          <a href="https://myradio.click" target="_new">
            https://myradio.click
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
